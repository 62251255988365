@import "assets/scss/variables";

.bannerSection {
  overflow: hidden;
  background-color: $color-gray-dark;

  .bannerContent {
    display: flex;
    flex-wrap: wrap;

    .item {
      width: calc(100% / 6);
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $screen-sm) {
        width: calc(100% / 3);
      }

      > img {
        width: 100%;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 50%;

        img {
          max-width: 100%;
        }
      }
    }
  }
}
