@import "assets/scss/variables";

footer {
  padding: 100px 15px;
  background-color: $color-main;

  @media (max-width: $screen-lg) {
    padding: 60px 15px;
  }

  @media (max-width: $screen-md) {
    padding: 50px 15px;
  }

  .footerContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;

    h2 {
      font: 700 64px/1.1 $second-font;
      margin-bottom: 50px;
      text-transform: uppercase;

      @media (max-width: $screen-lg) {
        font-size: 48px;
        margin-bottom: 30px;
      }

      @media (max-width: $screen-md) {
        font-size: 36px;
        margin-bottom: 20px;
      }

      span {
        height: 50px;
        display: inline-flex;
        align-items: center;
        margin: 0 15px;

        @media (max-width: $screen-lg) {
          height: calc(40px * 1.1);
          margin: 0 10px;
          transform: translateY(4px);
        }

        @media (max-width: $screen-md) {
          height: calc(30px * 1.1);
          margin: 0 8px;
        }

        img {
          height: 100%;
        }
      }
    }

    .links {
      display: flex;

      @media (max-width: $screen-sm) {
        flex-direction: column;
        align-items: center;
      }

      a {
        font: 700 64px/1.1 $second-font;
        color: $color-white;
        margin: 0 30px;
        position: relative;
        text-decoration: none;

        @media (max-width: $screen-lg) {
          font-size: 48px;
          margin: 0 20px;
        }

        @media (max-width: $screen-md) {
          font-size: 36px;
          margin: 0 15px;
        }

        @media (max-width: $screen-sm) {
          margin: 15px 0 0;
          max-width: max-content;
        }

        span {
          top: -20%;
          left: -15%;
          position: absolute;
          width: 130%;
          display: block;
          opacity: 0;
          z-index: 0;

          @media (max-width: $screen-md) {
            display: none;
          }

          &.tg {
            top: -15%;
          }

          img {
            width: 100%;
          }
        }

        //&::after {
        //  content: '';
        //  display: block;
        //  width: 0;
        //  height: 6px;
        //  left: 0;
        //  position: absolute;
        //  bottom: -4px;
        //  background-color: $color-white;
        //  transition: $fast-transition;
        //
        //  @media (max-width: $screen-sm) {
        //    height: 3px;
        //  }
        //}
        //
        //&:hover::after {
        //  width: 100%;
        //}

        &:hover span {
          opacity: 1;
        }
      }
    }

    .item {
      position: absolute;

      @media (max-width: $screen-sm) {
        display: none;
      }

      &.itemLeft {
        top: 30px;
        left: 100px;

        @media (max-width: $screen-md) {
          left: 0;
        }
      }

      &.itemRight {
        top: 10px;
        right: 100px;

        @media (max-width: $screen-md) {
          right: 0;
        }
      }
    }
  }
}
