body {

  .modal {
    max-height: 100vh;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    margin: 10vh auto;
    min-height: 80vh;
    min-width: 40vw;
    width: max-content;
    max-width: 80vw;

    @media (max-width: $screen-md) {
      margin: 5vh 5vw;
      min-height: 90vh;
      min-width: 90vw;
      max-width: 90vw;
    }
  }

  .modal-content {
    height: 100%;
    width: 100%;
    min-height: max-content;
    max-width: max-content;
    position: relative;
    border-radius: 0;
    border: 1px solid $color-black;
    margin: auto;

    @media (max-width: $screen-md) {
      min-height: 40vh;
    }
  }

  .modal-header {
    border-bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid $color-black;

    button {
      font-size: 22px;
    }
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    img, video {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
