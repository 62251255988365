@import "assets/scss/variables";

.descriptionSection {
  padding: 80px 0;
  background-color: $color-gray-light;

  @media (max-width: $screen-lg) {
    padding: 80px 0 30px;
  }

  .descriptionContent {

    .description {
      margin-bottom: 40px;

      h3 {
        font: 700 48px/1.2 $second-font;
        color: $color-main;
        margin-bottom: 20px;

        @media (max-width: $screen-lg) {
          font-size: 36px;
          margin-bottom: 15px;
        }

        @media (max-width: $screen-md) {
          font-size: 32px;
          margin-bottom: 10px;
        }
      }

      p {
        font: 300 20px/1.4 $main-font;
        margin-bottom: 0;

        &.text {
          border-bottom: 1px solid $color-main;
          padding-bottom: 15px;
        }

        &.warning {
          color: $color-main;
          font-weight: 500;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &:not(.text) {
          font-size: 16px;
          margin-bottom: 10px;

          @media (max-width: $screen-md) {
            font-size: 14px;
          }
        }

        @media (max-width: $screen-md) {
          font-size: 18px;
        }

        a {
          font-weight: 600;
          margin-left: 5px;
          color: $color-main;
          text-decoration: none;
        }
      }
    }

    .column {

      .item {
        margin-bottom: 20px;
        cursor: pointer;

        img, video {
          width: 100%;
        }
      }
    }
  }
}
