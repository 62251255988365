@import "assets/scss/variables";

.heroSection {
  background-color: $color-secondary;

  .heroContent {
    padding: 80px 0;
    min-height: 600px;
    height: calc(100vh - 230px);
    max-height: 900px;
    position: relative;

    @media (max-width: $screen-lg) {
      padding: 60px 0;
    }

    @media (max-width: $screen-md) {
      padding: 115px 0 50px;
      height: max-content;
      min-height: max-content;
    }

    .text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        margin-bottom: 100px;

        @media (max-width: $screen-lg) {
          margin-bottom: 50px;
        }

        @media (max-width: $screen-md) {
          margin-bottom: 20px;
        }
      }

      h3 {
        font: 300 24px/1.4 $main-font;

        @media (max-width: $screen-lg) {
          font-size: 20px;
        }

        @media (max-width: $screen-md) {
          font-size: 18px;
        }
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 80px;

      @media (max-width: $screen-lg) {
        bottom: 60px;
        max-width: 300px;
      }

      @media (max-width: $screen-md) {
        position: relative;
        bottom: 0;
        margin-top: 30px;
        max-width: 250px;
      }
    }
  }

  .heroImage {
    height: 140px;

    @media (max-width: $screen-lg) {
      height: 120px;
    }

    @media (max-width: $screen-md) {
      height: 100px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
