@import "assets/scss/variables";

.brandsSection {
  padding: 80px 15px;
  overflow: hidden;
  background-color: $color-white;

  @media (max-width: $screen-lg) {
    padding: 50px 15px;
  }

  .brandsContent {

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      filter: grayscale(1);
      height: 100%;

      &:hover {
        transform: scale(1.1);
        filter: grayscale(0);
      }
    }
  }
}
