@import "assets/scss/variables";

.heroSection {
  background-color: $color-gray-light;
  overflow: hidden;

  .row {
    @media (max-width: $screen-md) {
      margin: 0;
    }
  }

  .heroContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 80px 0 80px 15px;

    @media (max-width: $screen-md) {
      padding: 80px 15px 30px;
    }

    .items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .item {
        font: 700 48px/1.3 $second-font;
        width: 100%;

        @media (max-width: $screen-lg) {
          font-size: 36px;
          margin-bottom: 5px;
        }

        @media (max-width: $screen-md) {
          font-size: 28px;
          margin-bottom: 10px;
        }

        &.active a {
          font-size: 64px;
          color: $color-main;

          @media (max-width: $screen-lg) {
            font-size: 48px;
          }

          @media (max-width: $screen-md) {
            font-size: 36px;
          }
        }

        a {
          color: $color-black;
          text-decoration: none;
          max-width: max-content;

          &:hover {
            color: $color-main;

            img {
              transform: translateX(15px);
            }
          }

          img {
            width: 32px;
            margin-left: 15px;

            @media (max-width: $screen-lg) {
              width: 28px;
            }

            @media (max-width: $screen-md) {
              width: 24px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .text {
    margin-top: 100px;

    @media (max-width: $screen-md) {
      margin-top: 20px;
    }

    p {
      font: 300 24px/1.4 $main-font;
      max-width: 530px;
      padding-right: 20px;
      margin-bottom: 0;

      @media (max-width: $screen-lg) {
        font-size: 20px;
      }

      @media (max-width: $screen-md) {
        font-size: 18px;
      }
    }
  }

  .heroImage {
    padding: 0;
    position: relative;

    @media (max-width: $screen-md) {
      margin-bottom: 30px;
      padding: 0 15px;
    }

    @media (max-width: $screen-xs) {
      margin-bottom: 15px;
    }

    > img {
      width: 50vw;
      height: 100%;
      max-width: 960px;
      object-fit: cover;

      @media (max-width: $screen-md) {
        width: 100%;
        max-width: 600px;
      }
    }

    div {
      position: absolute;
      top: 200px;
      right: 50px;
    }
  }
}
