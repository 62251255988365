@import "assets/scss/variables";

.pricesSection {
  background-color: $color-gray-light;
  overflow: hidden;

  .pricesContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 15px;

    @media (max-width: $screen-lg) {
      padding: 60px 15px;
    }

    .text {
      margin-bottom: 30px;

      @media (max-width: $screen-lg) {
        margin-bottom: 20px;
      }

      h3 {
        font: 700 48px/1 $second-font;
        margin-bottom: 20px;

        @media (max-width: $screen-lg) {
          font-size: 36px;
          margin-bottom: 15px;
        }

        @media (max-width: $screen-md) {
          font-size: 28px;
          margin-bottom: 10px;
        }
      }

      p {
        font: 300 24px/1.4 $main-font;
        max-width: 800px;
        margin-bottom: 0;

        @media (max-width: $screen-lg) {
          font-size: 20px;
        }

        @media (max-width: $screen-md) {
          font-size: 18px;
        }
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-top: 1px solid $color-black;

      .item {
        font: 500 24px/1.3 $second-font;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        position: relative;
        color: $color-black;
        text-decoration: none;
        border-bottom: 1px solid $color-black;

        @media (max-width: $screen-lg) {
          font-size: 20px;
        }

        @media (max-width: $screen-md) {
          font-size: 18px;
        }

        @media (max-width: $screen-xs) {
          flex-direction: column;
          align-items: flex-start;
          border: 1px solid $color-black;
        }

        &:not(:last-child) {
          @media (max-width: $screen-xs) {
            margin-bottom: 20px;
          }
        }

        &:hover {

          .title {
            color: $color-main;
          }

          .itemImage {
            opacity: 1;
            transform: none;
            filter: grayscale(0);
          }

          .itemContent {
            transform: none;
          }

          .itemArrow {
            opacity: 1;
            transform: none;
          }
        }

        .itemImage {
          opacity: 0;
          min-width: 220px;
          max-width: 220px;
          transform: translateX(-270px);
          transition: $med-transition;
          height: 180px;

          @media (max-width: $screen-md) {
            min-width: 180px;
            max-width: 180px;
            transform: none;
            opacity: 1;
            filter: grayscale(1);
            height: 160px;
          }

          @media (max-width: $screen-xs) {
            height: 200px;
            min-width: 100%;
            max-width: 100%;
            border-bottom: 1px solid $color-black;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .itemContent {
          padding: 25px 50px 15px 50px;
          transform: translateX(-270px);
          transition: $med-transition;

          @media (max-width: $screen-lg) {
            padding: 20px 50px 10px 50px;
          }

          @media (max-width: $screen-md) {
            transform: none;
            padding: 15px 50px 5px 25px;
          }

          @media (max-width: $screen-xs) {
            padding: 20px 20px 10px;
          }

          .title {
            font: 700 30px/1 $second-font;
            margin-bottom: 15px;

            @media (max-width: $screen-lg) {
              font-size: 24px;
            }

            @media (max-width: $screen-md) {
              font-size: 22px;
            }
          }

          .prices {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: $screen-sm) {
              flex-direction: column;
            }

            .price {
              font: 400 22px/1 $main-font;
              margin: 0 25px 10px 0;

              @media (max-width: $screen-lg) {
                font-size: 20px;
                margin: 0 15px 10px 0;
              }

              @media (max-width: $screen-md) {
                font-size: 18px;
              }

              p {
                margin: 0;
              }

              span {
                color: $color-main;
                font-weight: 600;
              }
            }
          }
        }

        .itemArrow {
          opacity: 0;
          position: absolute;
          right: 0;
          top: calc(50% - 20px);
          margin: auto;
          transform: translateX(-20px);

          @media (max-width: $screen-lg) {
            width: 32px;
            top: calc(50% - 16px);
          }

          @media (max-width: $screen-md) {
            opacity: 1;
            width: 24px;
            top: calc(50% - 12px);
          }

          @media (max-width: $screen-xs) {
            top: calc(50% - 12px + 100px);
            transform: none;
            right: 15px;
          }

          img {

            @media (max-width: $screen-lg) {
              width: 32px;
            }

            @media (max-width: $screen-md) {
              width: 24px;
            }
          }
        }
      }
    }
  }
}
