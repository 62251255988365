@import "assets/scss/variables";

.linksSection {
  background-color: $color-gray-light;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .linksContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0 60px 15px;

    @media (max-width: $screen-lg) {
      padding: 60px 0 60px 15px;
    }

    @media (max-width: $screen-md) {
      padding: 60px 0 60px 15px;
    }

    @media (max-width: $screen-sm) {
      padding: 60px 15px 30px;
    }

    .text {
      margin-bottom: 30px;

      @media (max-width: $screen-lg) {
        padding-right: 15px;
      }

      h3 {
        font: 700 48px/1 $second-font;
        margin-bottom: 20px;

        @media (max-width: $screen-lg) {
          font-size: 36px;
          margin-bottom: 15px;
        }

        @media (max-width: $screen-md) {
          font-size: 28px;
          margin-bottom: 10px;
        }
      }

      p {
        font: 300 24px/1.4 $main-font;
        padding-right: 100px;
        margin-bottom: 0;

        @media (max-width: $screen-lg) {
          font-size: 20px;
          padding-right: 50px;
        }

        @media (max-width: $screen-md) {
          font-size: 18px;
          padding-right: 20px;
        }
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-top: 1px solid $color-black;

      .item {
        font: 500 24px/1.3 $second-font;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: $color-black;
        text-decoration: none;
        padding: 15px 100px 15px 0;
        border-bottom: 1px solid $color-black;

        @media (max-width: $screen-lg) {
          font-size: 20px;
          padding: 12px 48px 12px 0;
        }

        @media (max-width: $screen-md) {
          font-size: 18px;
          padding: 12px 36px 12px 0;
        }

        @media (max-width: $screen-sm) {
          padding: 12px 0;
        }

        &:hover {
          color: $color-main;

          img {
            opacity: 1;
            transform: none;
          }
        }

        .num {
          font-size: 18px;
          font-family: $main-font;
          color: $color-black;
          margin-left: 10px;
          opacity: 1;

          @media (max-width: $screen-lg) {
            font-size: 18px;
          }

          @media (max-width: $screen-md) {
            font-size: 16px;
            margin-left: 5px;
          }
        }

        img {
          opacity: 0;
          transform: translateX(-20px);

          @media (max-width: $screen-lg) {
            width: 32px;
          }

          @media (max-width: $screen-md) {
            width: 24px;
            opacity: 1;
          }

          @media (max-width: $screen-xs) {
            transform: none;
          }
        }
      }
    }
  }

  .linksImage {
    padding: 0;
    position: relative;

    @media (max-width: $screen-sm) {
      padding: 0 0 15px;
    }

    @media (max-width: $screen-xs) {
      padding: 0;
    }

    > img {
      width: 50vw;
      height: 100%;
      max-width: 960px;
      object-fit: cover;

      @media (max-width: $screen-xl) {
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: $screen-sm) {
        width: calc(100% + 20px);
        margin-left: -10px;
        height: auto;
        object-fit: initial;
      }
    }

    div {
      position: absolute;
      top: 200px;
      right: 50px;

      @media (max-width: $screen-lg) {
        top: 100px;
      }

      @media (max-width: $screen-md) {
        top: 70px;
        right: 30px;
        max-width: 150px;
      }

      @media (max-width: $screen-sm) {
        top: 20vw;
        right: 15vw;
        max-width: 25vw;
      }

      img {
        max-width: 100%;
      }
    }
  }
}
