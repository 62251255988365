@import "assets/scss/variables";

.projectsSection {
  padding: 80px 15px;
  background-color: $color-gray-dark;

  @media (max-width: $screen-lg) {
    padding: 60px 0;
  }

  @media (max-width: $screen-xs) {
    padding: 40px 0;
  }

  .projectsContent {

    .cards {
      display: flex;
      flex-wrap: wrap;

      .card {
        width: 25%;
        overflow: hidden;
        height: 370px;
        border: 1px solid $color-black;
        text-decoration: none;
        color: $color-black;
        background-color: $color-white;

        @media (max-width: $screen-lg) {
          width: calc(100% / 3);
        }

        @media (max-width: $screen-md) {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: auto;
          max-height: max-content;
        }

        @media (max-width: $screen-xs) {
          width: 100%;
        }

        &:not(:nth-child(4n)) {
          @media (min-width: $screen-lg) {
            border-right: 0;
          }
        }

        &:not(:nth-child(3n)) {
          @media (min-width: $screen-md) and (max-width: $screen-lg) {
            border-right: 0;
          }
        }

        &:not(:nth-child(2n)) {
          @media (min-width: $screen-xs) and (max-width: $screen-md) {
            border-right: 0;
          }
        }

        &:last-child {
          border-right: 1px solid $color-black !important;
        }

        &:not(:nth-last-child(-n+4)) {
          margin-bottom: 40px;

          @media (max-width: $screen-lg) {
            margin-bottom: 0;
          }
        }

        &:not(:nth-last-child(-n+3)) {
          @media (max-width: $screen-lg) {
            margin-bottom: 30px;
          }

          @media (max-width: $screen-md) {
            margin-bottom: 0;
          }
        }

        &:not(:nth-last-child(-n+2)) {
          @media (max-width: $screen-md) {
            margin-bottom: 30px;
          }

          @media (max-width: $screen-xs) {
            margin-bottom: 0;
          }
        }

        &:not(:nth-last-child(-n+1)) {
          @media (max-width: $screen-xs) {
            margin-bottom: 30px;
          }
        }

        &:hover {

          .cardImage img {
            filter: grayscale(0);
          }

          .cardBody {
            transform: translateY(-50px);

            @media (max-width: $screen-md) {
              transform: none;
              flex: 1;
            }

            h4 {
              color: $color-main;
            }
          }
        }

        .cardImage {
          width: 100%;
          height: 220px;
          overflow: hidden;

          @media (max-width: $screen-xs) {
            height: 200px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(1);
          }
        }

        .cardBody {
          padding: 20px 25px;
          height: 190px;
          display: flex;
          flex-direction: column;
          background-color: $color-white;
          border-top: 1px solid $color-black;

          @media (max-width: $screen-md) {
            height: max-content;
            padding: 25px;
          }

          @media (max-width: $screen-xs) {
            padding: 20px;
          }

          h4 {
            font: 700 24px/1 $second-font;
            margin-bottom: 10px;

            @media (max-width: $screen-lg) {
              font-size: 20px;
            }
          }

          p {
            font: 400 18px/1.3 $main-font;
            margin-bottom: 20px;

            @media (max-width: $screen-md) {
              font-size: 16px;
              margin-bottom: 15px;
            }
          }

          span {
            font: 500 18px/1.2 $main-font;
            display: flex;
            align-items: center;
            margin-top: auto;

            @media (max-width: $screen-md) {
              font-size: 16px;
            }

            img {
              width: 20px;
              margin-left: 10px;

              @media (max-width: $screen-md) {
                width: 16px;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
}
