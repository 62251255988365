@import "assets/scss/variables";

.aboutSection {
  padding: 80px 0;
  background-color: $color-gray-dark;

  @media (max-width: $screen-lg) {
    padding: 60px 0;
  }

  .item {
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: $screen-md) {
      align-items: flex-start;
    }

    &.itemBefore {
      margin-bottom: 50px;

      @media (max-width: $screen-lg) {
        margin-bottom: 40px;
      }

      @media (max-width: $screen-md) {
        margin-bottom: 30px;
      }

      .bg {
        right: 100%;

        @media (max-width: $screen-md) {
          left: -25px;
          top: -15px;
        }
      }
    }

    &.itemNow {

      .bg {
        left: 100%;

        @media (max-width: $screen-md) {
          left: -25px;
          top: -15px;
        }
      }
    }

    .text {
      position: relative;
      z-index: 1;

      h2 {
        font: 700 64px/1 $second-font;
        margin-bottom: 20px;
        position: relative;

        @media (max-width: $screen-lg) {
          font-size: 48px;
          margin-bottom: 15px;
        }

        @media (max-width: $screen-md) {
          font-size: 36px;
          margin-bottom: 10px;
        }

        span {
          color: $color-main;

          img {
            position: absolute;
          }

          &.lineAround img {
            height: 100px;
            top: -20px;
            left: -40px;

            @media (max-width: $screen-lg) {
              height: 80px;
              top: -15px;
              left: -35px;
            }

            @media (max-width: $screen-md) {
              height: 60px;
              top: -12px;
              left: -30px;
            }
          }

          &.lineBottom img {
            width: 500px;
            bottom: -30px;
            left: -25px;

            @media (max-width: $screen-lg) {
              width: 380px;
              bottom: -25px;
              left: -25px;
            }

            @media (max-width: $screen-md) {
              width: 300px;
              bottom: -20px;
              left: -25px;
            }
          }
        }
      }

      p {
        font: 300 24px/1.4 $main-font;
        max-width: 500px;

        @media (max-width: $screen-lg) {
          font-size: 20px;
        }

        @media (max-width: $screen-md) {
          font-size: 18px;
        }
      }
    }

    .bg {
      position: absolute;
      z-index: 0;

      h4 {
        font: 700 300px/1 $second-font;
        color: $color-white;
        opacity: 0.75;
        margin: 0;

        @media (max-width: $screen-lg) {
          font-size: 200px;
        }

        @media (max-width: $screen-md) {
          font-size: 80px;
        }
      }
    }
  }
}
