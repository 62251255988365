@import "assets/scss/variables";

.navbar {

  &.navbarLight {
    background-color: $color-gray-light;
  }

  .backLink {
    font: 500 24px/1.2 $main-font;
    text-decoration: none;
    color: $color-black;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: $screen-lg) {
      font-size: 20px;
    }

    @media (max-width: $screen-md) {
      font-size: 16px;
    }

    &:hover {
      color: $color-main;
    }

    img {
      width: 24px;
      margin-right: 15px;
      transform: rotate(-90deg);

      @media (max-width: $screen-md) {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
}
