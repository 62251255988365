@import "assets/scss/variables";

.loadingWrap {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: $color-white;

  img {
    animation: rotating 4s linear infinite;

    @media (max-width: $screen-md) {
      max-width: 150px;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
