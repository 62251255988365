body .navbar {
  height: 90px;
  padding: 0;
  display: flex;
  align-items: center;
  background-color: $color-secondary;
  border-bottom: 1px solid $color-black;

  @media (max-width: $screen-md) {
    height: auto;
    padding: 15px 0;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  .navbar-brand {
    font: 500 24px/1.3 $main-font;

    @media (max-width: $screen-lg) {
      font-size: 20px;
    }

    @media (max-width: $screen-md) {
      font-size: 18px;
    }
  }

  .navbar-toggler {
    outline: none;
    box-shadow: none;
    padding: 0;
    border: 0;
    border-radius: 0 !important;

    &.collapsed {

      span.icon-bar {
        background-color: $color-black;
      }
    }

    &:not(.collapsed) {

      .icon-bar {

        &:nth-child(1) {
          transform: translateY(10px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(0);
        }

        &:nth-child(3) {
          transform: translateY(-10px) rotate(-45deg);
        }
      }
    }

    span.icon-bar {
      display: block;
      width: 30px;
      height: 3px;
      margin: 0 0 7px;
      background-color: $color-black;
      transition: opacity, transform;
      transition-duration: .3s;
      transition-timing-function: cubic-bezier(0.7, 0, 0, 0.7);

      &:last-child {
        margin: 0;
      }
    }
  }

  .navbar-collapse {
    justify-content: flex-end;

    .nav-link {
      font: 500 20px/1.3 $main-font;
      color: $color-black !important;
      padding: 0 0 0 50px;
      position: relative;
      cursor: pointer;

      @media (max-width: $screen-lg) {
        padding: 0 0 0 30px;
      }

      @media (max-width: $screen-md) {
        font-size: 27px;
        padding: 0;
        max-width: max-content;
      }

      &:not(:last-child) {
        @media (max-width: $screen-md) {
          margin-bottom: 10px;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        left: 0;
        bottom: -4px;
        background-color: $color-black;
        transition: $fast-transition;
      }

      &:hover::after {
        width: 100%;
      }
    }

    .navbar-nav {
      @media (max-width: $screen-md) {
        padding: 30px 0;
      }
    }
  }
}
