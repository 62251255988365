.swiper {
  width: 100%;
  height: 100%;
  overflow: initial !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .swiper-wrapper {
    height: max-content;
    margin-bottom: 60px;
    display: flex;
    align-items: center;

    @media (max-width: $screen-lg) {
      margin-bottom: 40px;
    }

    @media (max-width: $screen-md) {
      margin-bottom: 30px;
    }
  }

  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    height: 100px;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      min-width: 100px;
    }
  }

  [class^="swiper-button"] {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 60px;
    height: 40px;
    margin: 0 10px;
    filter: grayscale(1);

    @media (max-width: $screen-lg) {
      width: 51px;
      height: 34px;
    }

    @media (max-width: $screen-md) {
      width: 39px;
      height: 26px;
    }

    &:hover {
      filter: grayscale(0);
    }

    &.swiper-button-prev::after {
      content: '';
      background: url("../img/arrow-swiper-prev-icon.svg") center no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }

    &.swiper-button-next::after {
      content: '';
      background: url("../img/arrow-swiper-next-icon.svg") center no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }

    &.swiper-button-disabled {
      filter: grayscale(1) !important;
    }
  }
}
