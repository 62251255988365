// Typography
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap');

@font-face {
  font-family: "BasisGrotesqueMonoPro";
  src: local("BasisGrotesqueMonoPro"),
  url("../fonts/BasisGrotesqueMonoPro-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "BasisGrotesqueMonoPro";
  src: local("BasisGrotesqueMonoPro"),
  url("../fonts/BasisGrotesqueMonoPro-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "BasisGrotesqueMonoPro";
  src: local("BasisGrotesqueMonoPro"),
  url("../fonts/BasisGrotesqueMonoPro-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "BasisGrotesqueMonoPro";
  src: local("BasisGrotesqueMonoPro"),
  url("../fonts/BasisGrotesqueMonoPro-Bold.ttf") format("truetype");
  font-weight: 700;
}

$main-font: "IBM Plex Sans", sans-serif;
$second-font: "BasisGrotesqueMonoPro", sans-serif;

body {

  h1 {
    font: 700 110px/1 $second-font;

    @media (max-width: $screen-lg) {
      font-size: 80px;
    }

    @media (max-width: $screen-md) {
      font-size: 48px;
    }
  }
}
