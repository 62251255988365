@import "assets/scss/variables";

.doodlesSection {
  height: 350px;
  overflow: hidden;

  @media (max-width: $screen-lg) {
    height: 300px;
  }

  @media (max-width: $screen-md) {
    height: 200px;
  }

  @media (max-width: $screen-sm) {
    height: 150px;
  }

  &.darkBg {
    background-color: $color-gray-dark;
  }

  &.lightBg {
    background-color: $color-gray-light;
  }

  .doodlesContent {
    position: relative;
    height: 350px;

    @media (max-width: $screen-lg) {
      height: 300px;
    }

    @media (max-width: $screen-md) {
      height: 200px;
    }

    @media (max-width: $screen-sm) {
      height: 150px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: calc(100% / 12);

      @media (max-width: $screen-md) {
        width: calc(100% / 8);
      }

      &:nth-child(1) {
        left: 0;
        top: -10%;

        @media (max-width: $screen-md) {
          top: 10%;
        }
      }

      &:nth-child(2) {
        left: calc(100% / 12);
        bottom: 40%;

        @media (max-width: $screen-md) {
          left: calc(100% / 8);
        }
      }

      &:nth-child(3) {
        left: calc(100% / 12 * 2);
        bottom: 10%;

        @media (max-width: $screen-md) {
          left: calc(100% / 8 * 2);
          bottom: 20%;
        }
      }

      &:nth-child(4) {
        left: calc(100% / 12 * 3);
        top: 10%;

        @media (max-width: $screen-md) {
          left: calc(100% / 8 * 3);
          top: 25%;
        }
      }

      &:nth-child(5) {
        left: calc(100% / 12 * 4);
        bottom: 10%;

        @media (max-width: $screen-md) {
          left: calc(100% / 8 * 4);
          bottom: 20%;
        }
      }

      &:nth-child(6) {
        left: calc(100% / 12 * 5);
        top: 40%;

        @media (max-width: $screen-md) {
          left: calc(100% / 8 * 5);
          top: 30%;
        }
      }

      &:nth-child(7) {
        left: calc(100% / 12 * 6);
        top: 70%;

        @media (max-width: $screen-md) {
          left: calc(100% / 8 * 6);
          top: 60%;
        }
      }

      &:nth-child(8) {
        left: calc(100% / 12 * 7);
        top: 10%;

        @media (max-width: $screen-md) {
          left: calc(100% / 8 * 7);
          top: 20%;
        }
      }

      &:nth-child(9) {
        left: calc(100% / 12 * 8);
        bottom: 20%;
      }

      &:nth-child(10) {
        left: calc(100% / 12 * 9);
        top: 10%;
      }

      &:nth-child(11) {
        left: calc(100% / 12 * 10);
        bottom: 20%;
      }

      &:nth-child(12) {
        left: calc(100% / 12 * 11);
        top: 40%;
      }

      &:nth-child(9), &:nth-child(10), &:nth-child(11), &:nth-child(12) {
        @media (max-width: $screen-md) {
          display: none;
        }
      }

      img {
        max-width: 80%;
        max-height: 100px;

        @media (max-width: $screen-md) {
          max-height: 50px;
        }
      }
    }
  }
}
