@import "assets/scss/variables";
@import "assets/scss/swiper";
@import "assets/scss/modal";
@import "assets/scss/header";

* {
  transition: $fast-transition;
  margin: 0;
}

.parallax {
  transition: none;
}

html {
  background-color: $color-main;
}

body {
  font-family: $main-font;
  max-width: 1920px;
  border-left: 1px solid $color-black;
  border-right: 1px solid $color-black;
  margin: 0 auto !important;
  overflow-x: hidden;
  transition: none;
}

header, main, section, footer {
  overflow-x: hidden;
  max-width: 100vw;
}

.container {
  @media (max-width: $screen-xs) {
    padding: 0 15px !important;
  }

  @media (min-width: $screen-xs) {
    max-width: 100% !important;
    padding: 0 30px !important;
  }

  @media (min-width: $screen-lg) {
    max-width: 1300px !important;
  }

  @media (min-width: 1400px) {
    max-width: 1440px !important;
  }
}

.container-fluid {
  padding: 0 !important;
  max-width: 1920px;
}

p br, h3 br {
  @media (max-width: $screen-sm) {
    display: none;
  }
}

img {
  pointer-events: none !important;
}
